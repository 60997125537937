import {FIELDS_GAP, FORMS_TEMPLATE_IDS, SPECS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React, {useEffect} from 'react';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {FormOverrides} from '@wix/form-viewer/dist/types/services/form-overrides';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {getCustomerDetailsFormOverrides} from './CustomerDetailsForm.utils';
import {CustomerDetailsFormSection} from '../../../../types/app.types';
import {classes} from './CustomerDetailsForm.st.css';
import {FormProps} from '../form.utils';

export interface CustomerDetailsFormProps extends FormProps {
  formSections?: CustomerDetailsFormSection[];
  dataHook?: string;
}

const CustomerDetailsFormInternal = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  formSections,
  dataHook,
}: CustomerDetailsFormProps) => {
  const i18n = useICUI18n();
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {isDonationsTheme},
    checkoutSettingsStore: {checkoutSettings},
    checkoutStore: {isShippingFlow, isFastFlow},
    memberStore: {isMember, currentUserEmail},
  } = useControllerProps();
  const {experiments} = useExperiments();

  useEffect(() => {
    if (isMember && experiments.enabled(SPECS.EnableRulesForFormComposer)) {
      formValues.email = currentUserEmail;
    }
  });

  return (
    <div className={classes.root} data-hook={dataHook}>
      <FormViewer
        i18n={i18n}
        ref={formRef}
        formId={FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO}
        values={formValues}
        errors={formErrors}
        onChange={setFormValues}
        overrides={
          getCustomerDetailsFormOverrides({
            localeKeys,
            isDonationsTheme,
            checkoutSettings,
            excludeGoogleAutoComplete: experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
            shouldFilterByShippingCountries: experiments.enabled(SPECS.FilterCountriesByShippingCountries),
            isShippingFlow,
            isMember,
            formSections,
            isFastFlow,
          }) as FormOverrides
        }
        onValidate={setFormErrors}
        style={{
          styleParams: {
            numbers: {
              rowSpacing: FIELDS_GAP,
            },
          },
        }}
      />
    </div>
  );
};

export const CustomerDetailsForm = React.memo(CustomerDetailsFormInternal);
